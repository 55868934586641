/**
 * This interface defines environment structure.
 */
export abstract class EnvironmentConfig {
    /**
     * Environment name.
     */
    name: string;

    /**
     * Is this a "production" environment.
     * It enables additional debugging / development options that should not be enabled in a publicly available application.
     */
    production: boolean;

    /**
     * Sentry DSN used for sentry.io integration.
     */
    sentryDSN: string;

    /**
     * Application build tag.
     * This can be considered a version string.
     */
    buildTag: string;

    /**
     * Backend API URL.
     */
    backend: string;

    /**
     * URL endpoint for Auth0 authentication and authorization.
     */
    auth0: string;
}
